import domReady from "@wordpress/dom-ready";
import Swiper from "swiper";

domReady(() => {
  const blogCarouselBlocks = document.querySelectorAll(".blog-carousel-block");

  if (0 < blogCarouselBlocks.length) {
    blogCarouselBlocks.forEach((carouselBlock) => {
      new Swiper(carouselBlock.querySelector(".blog-carousel__carousel"), {
        slidesPerView: 1.3,
        spaceBetween: 20,
        loop: false,

        navigation: {
          prevEl: carouselBlock.querySelector(".swiper-button-prev"),
          nextEl: carouselBlock.querySelector(".swiper-button-next"),
        },

        breakpoints: {
          768: {
            slidesPerView: 2.2,
            spaceBetween: 40,
          },
          1080: {
            slidesPerView: 3.2,
            spaceBetween: 50,
          },
        },
      });
    });
  }
});
